import { render, staticRenderFns } from "./LittleFaq.vue?vue&type=template&id=2a8def42&scoped=true&"
import script from "./LittleFaq.vue?vue&type=script&lang=js&"
export * from "./LittleFaq.vue?vue&type=script&lang=js&"
import style0 from "./LittleFaq.vue?vue&type=style&index=0&id=2a8def42&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a8def42",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Faq: require('/app/components/Faq.vue').default})
